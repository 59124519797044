@import '../../../css/variables';
@import '../../../css/functions';

.setup {
	.step.years {
		flex-grow: 1;
		justify-content: flex-end;
		width: 65%; 
		.form-group { width: 45%; }
		.info-container { display: flex; font-style: italic; }
		.help-text-container{ width:100%; margin-top:8px;}     
	}
}
@include breakpoint('tablet') {
	.setup .step.years{justify-content: center;}
}