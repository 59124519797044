@import '../../../css/variables';
@import '../../../css/functions';

.result {
  .step.not-a-candidate {     
    .panel { 
      padding: 20px; 
    }
  .top {
    // IE11 and below
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
       width: 100%;
            }
    .panel { 
      padding-top:25px; 
      padding-bottom:15px;
    .content { 
      width: 70%; 
      display: flex;
      flex-direction: column;
      align-items: center;
    .t4 { font-weight: bold; }
      @include breakpoint('tablet') {width: 100%;
      }
    p { text-align: center; }
    .quote{ 
      @include breakpoint('tablet') {
      &{ width: 100%; }
      }
    p { text-align: left; margin-bottom:0; }

    border-left:4px solid $macaroni-and-cheese; 
    margin-left:22px; 
    margin-bottom:25px; 
    padding-left:15px; 
    text-align:left;
    margin-top:10px;
    h2 { font-family: Knockout; font-weight:normal; }
    .t2{ font-size:remSize(18); font-style:italic;}
    .t11{ margin-left: 7px;}
                    }
                }
            }
        }
    &.links {
      flex-direction: row;
      justify-content: center;
      align-items: center;
        }
    .bottom { 
      width: 100%;
      .panel { 
       .content {
         width: 80%; 
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         align-items: center;
         text-align: center;
         .t2 { font-size: remSize(18); margin-bottom:10px;  }
         @include breakpoint('tablet') {
                &{ width: 100%; }
                    }
                }
            }
        svg { margin-right: 10px; }
        }
        .btn-link{ padding:0;}       
    }
}


