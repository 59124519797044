@import '../../css/variables';
@import '../../css/variables';
@import '../../css/functions';

.btn {

  &.btn-next {
    background: $macaroni-and-cheese;
    color: $indigoBlue;
    border-radius: 24px;
    padding-top:remSize(10);
    padding-bottom:remSize(10);
    padding-right:remSize(40);
    padding-left:remSize(40);
    font-weight: bold;
    min-width: 220px;
    min-height: 55px;
    font-size:1.12rem;
    white-space: nowrap;
    margin: 10px;

    .arrow {
      background-position: center center !important;
      background-color: transparent !important; 
      background-repeat: no-repeat !important;
      width: 20px;
      min-height: 10px;
      margin-left: 10px;
      display: inline-block;
      background: $macaroni-and-cheese;
      color: $indigoBlue;
      background: url('/images/Icon_Purple_Chevron.svg');
      transform: rotate(-90deg);
      position: relative;
      left:0;
      transition: all .2s ease-in-out;
      -webkit-transition: all .2s ease-in-out;
    }

    @include breakpoint('mobile') {
      white-space:normal;
      width:100%;
      margin-left:0;
      margin-right:0;
    }

    &:hover {
      background: $darkOrange;
      color: $indigoBlue;

      .arrow{
        transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        left:8px;}
      }
    }
  }

  .page.rtl {
    .btn.btn-next {
      .arrow { 
        margin-right: 10px; 
        transform: scaleX(-1); 
        transform: rotate(-270deg);
      }
    }
  }