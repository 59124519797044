// Right To Left Languages Overrides

@import '../../css/variables';

.page.rtl {
	text-align: right;

  .btn.btn-next .svg-inline--fa {
  	transform: scaleX(-1); 
  }

  .home .intro .top .panel-container .panel,
  .practice .step.intro1 .bottom .panel,
  .begin .bottom .panel {
  	text-align: right;
  }
  .begin{
    .narrow-content {
      .mona-lisa{
        margin-left:10px;
      }
    }
  }

  .the-progress-bar .progress .progress-bar{ right: 0;}

  .the-progress-bar .phases .phase {
    float: right;
    
    &:nth-child(1) .phasediv {
			text-align: right;
    }
    
  	&:nth-child(5) .phasediv {
			text-align: left;
    }
    
  }
  .btn-link .arrow{transform: rotate(-270deg) !important;}
  .setup .ears .content .bottom .help-text-container img,
  .icon.mona-info,
  .help-text-container img,
  .setup .form .big-checkbox input[type=checkbox],
  .practice .choose-sound .links .btn-link img,
  .test .choose-sound button img,
  .test .btn-pause img,
  .icon.small {
  	margin-right: 0;
  	margin-left: 10px;
  }
  .speakdiv{ text-align: right !important;}

  .test .step.unsure .bottom .links .contact-info .btn-link{ text-align: right;}
  .btn.btn-back, 
  .help-text, 
  .result .step.intro section.panel .content .text-box p, 
  .setup .volume .help-text-container, 
  .setup .form .form-group {
    text-align: right;
    &.has-error{
      input{ background-position: left;}
    }
  }
  
  .result .panel h2, .not-a-candidate .panel .quote p { text-align: right !important;}
  .result .step.not-a-candidate .top .panel .content .quote{ border-right:4px solid #fdc82f; border-left:none; padding-right:15px; padding-left:0;}
  
  
  .practice .btn-repeat, .practice .btn-skip,
  .test .btn-pause {
  	align-self: flex-end;
  }

  .practice.panel .step .t3 
  {
  	margin-right: 5px;
  }

  .result .step.candidate .top .quote {
  	border-left: 0;
  	margin-left: 0;
  	padding-left: 0;
  	border-right: 4px solid $macaroni-and-cheese;
    margin-right: 22px;
    padding-right: 15px;
    text-align: right;
  }

	// Modifiers override
	.text-left {
		text-align: right;
	}

	.text-right {
		text-align: left;
  }
  
  @include breakpoint('tablet') {
    .footer-logo{ text-align: right !important;}
    .footer-links{ text-align: right !important;}
    .copyright{ text-align: right !important;} 
   }

  .home {
    .intro {
      .top {
          background-image: url(/images/LIF1013_H_hr_reversed.jpg);
      }
    }
  }
  
  .call-parent .btn-link, .speakdiv .btn-phone{
    direction: ltr;
    padding-right: 5px;
  }
}