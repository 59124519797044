@import '../../css/variables';
@import '../../css/functions';

.begin {

  background: $grey1;
  flex-grow: 1;
  text-align:center;
  display:flex;
  flex-direction:column;
  align-items:center;

  img {
    width: 43px;
    height: 50px;
    margin: 0 0 10px 0;
    float:left;
    font-size:0.875rem;
    white-space: nowrap;
  }

  .top.panel { 
    width: 100%; 
    margin-bottom: remSize(26); 
    flex-grow: initial;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  .bottom {
    display: flex; 

    @include breakpoint('mobile') {
      flex-wrap: wrap;
    }

    // IE11 and below
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
      width: 100%;
    }

    .panel{
      padding: remSize(12) remSize(28) remSize(6) remSize(20);
      flex-direction: column;
      align-items: flex-start;
      margin: remSize(8);
      text-align: left;
      justify-content: flex-start;


      @include breakpoint('mobile') {
        flex-direction: row;
      }
    }

    @include breakpoint('mobile') {
      img{ margin-right:12px;}
    }
  } 

  .narrow-content {
    width: 70%;
    display:flex;
    margin:0 auto;
    justify-content: center;
    
    .mona-lisa{ margin-right:10px;}
  }

  .info-btn {
    p{
      text-align:left; 
      margin:0;
    }

    img { 
      font-size:25px; 
      color: $indigoBlue; 
    }
  }

  @include breakpoint('tablet') {
    .col-sm{ 
      flex-basis: auto;
    }
    
    .narrow-content{ 
      width:100%;
    }
  }

  .footnote{
    margin-top: remSize(20);
    font-size:0.875rem;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $slate-grey;
    line-height:15px; 
    margin-bottom: 20px;

    a { 
      color: $black; 
      font-size: 14px; 
      white-space: nowrap; 
      padding: 5px; 
    }
  }
}