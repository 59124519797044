@import '../../../css/variables';
@import '../../../css/functions';

.setup {
  .ears {
    .content {
      @include breakpoint('mobile') {
        width:100%;}
        .bottom {
          .help-text-container {  
            display: flex; 
            justify-content: center; 
            margin-bottom: 10px;
            .one-ear {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            }          
            img { margin-right: 10px; }
          }
          .btn-next{ line-height:35px;}
        }
      }
    }
  }