@import '../../../css/variables';
.page{
  .practice {
    .intro2 {
      flex-grow:1;
      justify-content:flex-start;
      text-align:center;

      .btn-next {
       background: $indigoBlue;
       color: $white;
       margin-top:20px;

       .arrow { 
         background: url('/images/Icon_White_Chevron.svg'); 
       }
       &:hover{ 
         background: $lightPurple; 
         color:$white;
       }  
     }
   }
 }
}