@import '../../../css/variables';

.practice {
	.help {
		width:50%;
		text-align:center;
		h2{ color: $indigoBlue;}
		.t5{ font-style:italic; color:$grey2; margin-bottom:25px;}

		.btn-next {
			img { width: 30px; }
		}
	}
}