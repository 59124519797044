@import '../../../css/variables';

.page {
  .body-content{
    .practice {
      .thanks {
        justify-content: center;
        width:60%;
        text-align:center;
        h2{ color: $indigoBlue;}
        .btn-next{white-space:normal;}

        p { margin-top: 20px; margin-bottom: 20px; }

        .t3 { font-style: italic; }
      }    
    }
  }
}