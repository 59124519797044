@import '../../../css/variables';
@import '../../../css/functions';

.test {
  .step.unsure {
    h2{ margin-top:remSize(30);}
    .panel { width: 100%; margin-bottom: 10px; padding: 20px; }
    .bottom { 
      display: flex;
      margin-left: -15px;
      margin-right:-15px;
      justify-content: center;
      width:100%;         
      @include breakpoint('tablet') {
        &{ display:block; 
        margin-left:0;
        margin-right:0; }
        }
  .panel {   
    @include breakpoint('tablet') {
    &{margin-left:0;
    margin-right:0; }
    }
    }
    .continue {
    button { color: $indigoBlue; font-weight: bold; }
    }
    .links {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display:flex;
      width:70%;
      text-align: center;
    svg { margin-right: 10px; }
    @include breakpoint('mobile') {
     text-align: center;
     display: flex;
     flex-direction: column;
     width: 100%;
     a { display:block; }  
     article { margin-bottom:10px;}
     }
    .contact-info{ display: flex; justify-content: center;
      .icon.small{ height:44px; margin-right:0;}
      .btn-link{text-align:left;}
      .smallfont{ font-style: italic; margin:0;}
      @include breakpoint('tablet') {
      &{flex-wrap:wrap; justify-content: left; }       
                   }
      @include breakpoint('mobile') {
      &{flex-wrap:wrap; }
               }
    div{ margin: 0 15px;}
    .speakdiv{ 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    }            
    }   
}           
}
.info1{ 
  width:60%; 
  text-align:center;
  @include breakpoint('tablet') {
  width:100%;
            }
  .btn-link{ 
    font-weight:$bold; 
    letter-spacing: 0.3px; 
    vertical-align: text-bottom;
    border:1px solid $indigoBlue; 
    border-radius: 24px;
    min-width: 220px;
    min-height: 55px;
    font-size: 1.12rem;
    white-space: nowrap;
    margin: 10px;
    outline: none;
    text-decoration: none;
    padding: .375rem .75rem;
    @include breakpoint('mobile') {
      width:100%;
      margin-left:0;
      margin-right:0;      
      }
    &:hover{border: 3px solid $indigoBlue; padding: .375rem .625rem; color:$indigoBlue;
      .arrow{
        transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        left:5px;
        }
    }             
    .arrow {
      background-position: center center !important;
      background-color: transparent !important; 
      background-repeat: no-repeat !important;
      min-width: 20px;
      min-height: 10px;
      margin-left: 10px;
      display: inline-block;       
      background: $macaroni-and-cheese;
      color: $indigoBlue;
      background: url('/images/Icon_Purple_Chevron.svg');
      background-size: 100%;
      transform: rotate(-90deg);
      position: relative;
      left:0;
      }
    }
    .btn-next{ margin-right:0;}        
    }
    .btn-link{ 
    font-weight:$bold; 
    letter-spacing: 0.3px; 
    padding: 0;    
    }
    .btn-next{ 
      padding-right:remSize(20);
      padding-left:remSize(20); 
      @include breakpoint('mobile') {
        &{margin:0;}
        }
    }        
    .call, .email{ 
      font-size: 1.125rem; 
      padding-right: 6px;}
    }
}
@include breakpoint('mobile') {
  .info1 { width: 100% !important; }
  
 }