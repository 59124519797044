@import '../../../css/variables';
@import '../../../css/functions';


.panel {
  .setup {
  .form {
    section { width: 100%;
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        margin-bottom:25px;
       }
    
    }
      @include breakpoint('tablet') {
      width:100%;
      }
    .form-field { 
    display: flex;
      @include breakpoint('tablet') {
      display:block;
      }       
      .input-field { 
      width: 50%;
      &.full-width {
        width:100%;
      }
        @include breakpoint('tablet') {
        width: 100%;
                }
            }
        }
    .form-group { 
      text-align: left; 
      margin-left:remSize(10);
      margin-right:remSize(10);
      &.has-error input {
        background: url('/images/exclamation.png') no-repeat scroll right;
        background-size: 15px 15px;
        padding-right: 5px;
        background-origin: content-box;
        border: 1px solid $red;
        }
      .error-msg { color: $red; font-size: remSize(14); font-style: italic; display: block;}
      .radio-control {
        &.control-label {
          min-height: 27px;
          padding-left: 29px;
          cursor: pointer;
          &:hover {
            color: $indigoBlue;
            input~.radioPoint {
              border-color: $indigoBlue;
            }
          }
        }
        position: relative;
        input {
          display: none;
          &:checked~.radioPoint {
            border-color: $indigoBlue;
            &::after{
              display: block;
            }
          }
        }
        .radioPoint {
          cursor: pointer;
          position: absolute;
          top: 6px;
          left: 0;
          height: 17px;
          width: 17px;
          background-color: $white;
          border-radius: 50%;
          border: 1px solid $borderColor;
          &::after {
            content: '';
            display: none;
            position: absolute;
            border-radius: 50%;
            background: $indigoBlue;
            top: 4px;
            left: 4px;
            width: 7px;
            height: 7px;
          }
        }
      }
      .control-label {
        font-size: 16px;
        color: $slate-grey;
        min-height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom:0;
        // @include breakpoint('mobile') {
        //     min-height: 65px;
        // }
        @include breakpoint('tablet') {
          min-height: auto;
                    }
        .field-info-text {
          font-size: remSize(14);
          font-style: italic;
          color: $grey2;
          line-height:remSize(12);
          margin-bottom:5px;
            }
        }    
        .control-label[for=firstName], .control-label[for=lastName] { min-height: unset; }
          @include breakpoint('tablet') {
            margin-top:remSize(16);
            margin-bottom:remSize(16);
            }
            // IE11 and below
        // @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
        // width: 100%;
        // }
        &.has-error {
        .control-label { color: $red; }
        }
        }
        .btn-link { padding: 0px; }
        input#postcode { width: 50%; }
        .marketing-consent {
        // display: flex;
        // justify-content: center;
        font-size: 16px;
        color: $black;
        font-style:italic;
        margin-bottom: 10px;
        //input { margin-top: 5px; margin-right: 5px; }
                }
        .privacy-policy {
          font-size: 14px;
          color: $black;
          font-style:italic;
          a { color: $black; font-size: 14px; white-space: nowrap; font-weight:normal; }
                }
            .big-checkbox {
                display: flex;
                input[type=checkbox] {
                    margin-right: 5px;
                    margin-top: 2px;
                    //transform:scale(1.3, 1.3);

                    zoom: 1.5; 

                    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                        zoom: 1;
                    }

                    @supports (-webkit-overflow-scrolling: touch) {
                        zoom: unset;
                    }
                }
            }
        }
    }
}