@import '../../css/variables';
@import '../../css/functions';

.btn.btn-back {
  width:100%;
  text-align:left;
  background:none;
}
.setup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 1rem 0 0 0;
  width:100%;
  margin:0 auto;
  text-align:center;
  align-items: center;
  padding-top:remSize(48);
  &.grow-vertical {
    //flex-grow: 1;
  } 
  .step{
    width:65%;
  }
  section { 
    margin-bottom: 0.75rem;    
    @include breakpoint('mobile') {
      width:100%;
    }
  }
  .buttons { 
    display: flex; 
    align-items: center; 
  }
  @include breakpoint('mobile') {
   &{ width: 100%; padding-top:remSize(16); }
   .narrow { width:100% }
   .buttons{ display:flex; flex-direction: column;}
 }
}