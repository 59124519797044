@import '../../../css/variables';
@import '../../../css/functions';

.setup {
  .never {
    .btn.radio-btn{
      max-width:remSize(190);
      min-height:remSize(140);
      max-height:remSize(115);
      padding-top: 0;
      padding-bottom: 0;
      padding-right: remSize(40);
      padding-left: remSize(40);
      white-space:normal;
      &:hover { 
        padding-right: remSize(36);
        padding-left: remSize(36);
      }
      &.active {
        padding-right: remSize(35);
        padding-left: remSize(35);
      }
    }
    .bottom {
      min-height: 70px;
      .help-text-container { display: flex; justify-content: space-between; align-items: center; }
      .btn-next{ line-height:35px;}
    }
  }
}