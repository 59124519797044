@import '../../css/variables';
@import '../../css/functions';

.app-header {
  background-color: white;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.11);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.11);
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.11);
  position: relative;

  .cochlear-header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1108px;
    margin:0 auto;  

    button { font-size: 18px; white-space: nowrap; color: $black; text-decoration: none; }

    .mobile-header{ display: none;}

    @include breakpoint('tablet') {
      .desktop-header{ display: none;}
      .mobile-header{ 
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 5px solid $macaroni-and-cheese;
        padding: 5px 0;

        img{ height: 30px;}
      }
      
    }

    @include breakpoint('mobile') {
      .desktop-header{ display: none;}
      .mobile-header{ 
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 5px solid $macaroni-and-cheese;
        padding: 5px 0;

        img{ height: 30px;}
      }
    }

    img {
      height: 89px;
      object-fit: contain;
      padding: 5px;
    }

    margin-bottom: 4px;
  }
}