@import '../../../css/variables';
@import '../../../css/functions';

.home {

  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    position: relative;

    .top {

      background-image: url(/images/LIF1013_H_hr.jpg);
      background-repeat: no-repeat; 
      background-size: 130%;      // zoom in
      background-position:64% 24%;
      position: relative;
      display: flex;
      flex-grow: 1;
      object-fit: cover;

      .panel-container {
        max-width: 1108px;
        margin: 0 auto;
        padding:0 10px;
        width:100%;
        display:flex;
        flex-direction: column;

        .panel {
          
         background: $macaroni-and-cheese;
         border-radius: 0;
         text-align: left;
         border-radius: 0 0 40px 0;
         padding:2.81rem;
         width: 45%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         border:none;

       // IE11 and below
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          article { 
            width: 100%; 
            // height:80vh; 
          } 
         }

       @include breakpoint('tablet') {
         h1 { font-size: 30px; }
         p { font-size: 0.8em; }
       }

       .btn-next { 
         background: $white;
         @include breakpoint('mobile') {
           margin:0;
         }

         &:hover{ 
          background:$purple; 
          color:$white;

          .arrow { 
            background: url('/images/Icon_White_Chevron.svg'); 
            transition: all .2s ease-in-out;
            -webkit-transition: all .2s ease-in-out;
            left:8px;
          }
        }    
      }
    }

    @include breakpoint('mobile') {
      .panel { 
        width: 100% !important; 
        border-radius: 0px;
      }
      margin: 0px;
    }
  }
}

.bottom {
  margin-bottom: 10px;
  max-width: 1108px;
  padding: 0 10px 10px 10px;

  .disclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p{ line-height:15px; margin-bottom:0;}

    .australia-only { font-weight: bold; }

    .nal { margin-left: 10px; width: 60px; height: 42px;
     @include breakpoint('tablet') {
       width:auto;
     }     
   }
 }

  // IE11 and below
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    // height:20vh;
   }
}
}
}