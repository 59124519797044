@import './css/variables';
@import './css/functions';

// Bliss Pro font definitions
// use font-weight:normal for Regular and 300 for Light
@font-face {
   font-family: 'BlissPro';
      src: url('#{$fontsPath}/BlissPro-Regular.eot');
      src: url('#{$fontsPath}/BlissPro-Regular.woff2') format('woff2'),
           url('#{$fontsPath}/BlissPro-Regular.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}
@font-face {
   font-family: 'BlissPro';
      src: url('#{$fontsPath}/BlissPro-Light.eot');
      src: url('#{$fontsPath}/BlissPro-Light.woff2') format('woff2'),
           url('#{$fontsPath}/BlissPro-Light.woff') format('woff');
   font-weight: 300;
   font-style: normal;
}

@font-face {
  font-family: 'Knockout';
     src: url('#{$fontsPath}/Knockout-28.eot');
     src: url('#{$fontsPath}/Knockout-28.woff2') format('woff2'),
           url('#{$fontsPath}/Knockout-28.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: BlissPro, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size:remSize(24);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@include breakpoint('mobile') {
    html {
      font-size: remSize(14); 
    }
}
