@import '../../../css/variables';
@import '../../../css/functions';

.practice {

  .choose-sound {

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
      h1, h2, h3, h4, h5 { width: auto; }
      p { width: auto; } 
      span:not(.bar) { width: auto; } 
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;

    .buttons {
      display: flex;
      justify-content: space-between;

      @include breakpoint('mobile') {
        & { display: block; }
      }
    }

    .t4 { color:$grey2; font-style: italic; margin-top:30px; font-size:remSize(20);}
    h3 { width: 50%; }

    @include breakpoint('mobile') {
      h3 { width: 100%; }
    }

    .links {
      color: $indigoBlue;
      margin-bottom: 20px;

      // Edge

      // @supports (-ms-ime-align:auto) { width: 80%;  }

      // @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
      //   width: 80%;
      // }

      &.disabled { 
        color: $grey2; 
        button span { color: $grey2; } 
      }

      .btn-link { 
       img { margin-right: 10px; }              
       white-space: nowrap; 
      }
    }
  }
}