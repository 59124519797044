@import '../../../css/variables';
@import '../../../css/functions';

.test {
  .mona-lisa{ margin:10px 0;}
  .stop { width:65%; text-align:center;
    .btn-next{ margin-bottom:40px;}
    .btn{padding: .375rem .35rem;}
    .contact-info{ display: flex; justify-content: center;
      .icon.small{ height:38px; margin-right:0;}
      .btn-link{text-align:left;}
      .smallfont{ font-style: italic; margin:0;}
      @include breakpoint('tablet') {
        &{flex-wrap:wrap; justify-content: left; }
      }
      @include breakpoint('mobile') {
        &{flex-wrap:wrap; }    
      }
      div{ margin: 0 3px;} 
      .speakdiv{ 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
      }
      .call, .email{ font-size: 1.125rem;}               
    }   
    .icon.mona{ margin-top:10px; margin-bottom:10px;}
  }
}
