@import '../../../css/variables';
@import '../../../css/functions';

.setup {
  .volume {
    .percent{ color:$indigoBlue; }
    .help-text-container {  
      display: flex; 
      justify-content: center; 
      align-items: flex-start; 
      margin-bottom: 10px;
      text-align:left;
      width:80%;
      margin-top:15px;
      font-size: 1rem;

      @include breakpoint('mobile') {
        width:100%;
        }
      
  .btn-link { font-size: 1rem; padding-left: 0px; margin:0; font-weight:normal; }
    span{ font-style:italic; color:$indigoBlue;}
        }
    .ios { display: none;}
        // @supports (-webkit-overflow-scrolling: touch) {
        //     /* CSS specific to iOS devices */ 

        //     .ios { display: block; }
        //     .not-ios { display: none; }
        //   }
    .btn-link { margin-top: 8px; line-height:20px;        
      &.disabled p span { color: $grey2; }
    }
      @include breakpoint('mobile') {
        .btn.btn-next{ white-space:normal;}
        .volume .buttons .btn-volume{width: 60px; height:60px; font-size:30px;}
        }
    }
}

