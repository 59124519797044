@import '../../../css/variables';
@import '../../../css/functions';

.test {
  .breather {
  text-align:center;
  width:50%;
  @include breakpoint('tablet') {width: 100%;
  }
    }
}