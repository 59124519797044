@import '../../../css/variables';
@import '../../../css/functions';

.result {
  .step.candidate {
    .top {
      .panel{ padding-top:35px; padding-bottom:20px;}
      .t2 { font-style: italic;   }
      .t4 { font-weight: bold; }
      .content { width: 70%; text-align:center;       
        @include breakpoint('tablet') {width:100%;}
      }
      @include breakpoint('mobile') {
        .content { width: 100%; }
      }
      .quote{           
        border-left:4px solid $macaroni-and-cheese; 
        margin-left:22px; 
        margin-bottom:25px; 
        padding-left:15px; 
        text-align:left;
        h2 { font-family: Knockout; font-weight:normal; }
        .t2{ font-size:18px; font-style:italic;}
      }
    }
    &.links {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .bottom {
     display: flex; 
     .btn-next{line-height: 31px; margin-left:0; margin-right:0;}
     .smallfont{ font-style: italic; font-size: 0.875rem;}
       // IE11 and below
       @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        width: 100%;
      }
      .t2, .t3, .t4 { font-size: 18px; }
      section{margin-bottom:0;}
      .panel { 
        align-items: flex-start;
        //margin-bottom: 30px;
      }

      .call{ margin-right:5px}
      .email{ margin-right:5px;}
      svg { margin-right: 10px; }
      .links { margin-bottom: 10px;
        @include breakpoint('tablet') { a{display:flex; flex-wrap:wrap;} }
        .speakdiv{ 
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          text-align: left;
          font-size: 1rem;
          margin-bottom: 8px; 
        }
        .btn-phone{ padding:0;}    
      }
    }
    .disclaimer {
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        width: 100%;
      }
      p{ line-height:15px;}
    }

    .btn-link{  padding:0 0 12px 0;}
  }
}


