@import './functions';

.page {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $grey1;

  .panel { 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //justify-content: center;
    align-items: center;
    background: $white;
    border: 1px solid $borderColor;
    border-radius: 0 0 30px 0;
    padding: remSize(16);
    width:100%;
    position:relative;
    flex-grow:1;
    margin-bottom:remSize(16);


    &.panel-border {
      border-left-color: $macaroni-and-cheese; 
      border-left-width: 8px;
      //border-left-width: .40rem;
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }

  .app-body { 
    background: $grey1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .body-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
    }
  }

  .api-error { 
    color: $red;     
    margin-left: auto;
    margin-right: auto; 
  }

  h1{ 
    font-weight:$bold;
    @include fontSize(48);
    margin-bottom:1.40rem;
  }

  h2{ 
    @include fontSize(36);
    font-weight:$bold;
  }

  h3{ 
    @include fontSize(28);
  }

  h1,h2,h3,h4,h5 {
    color:$slate-grey; 
  }

  p{ 
    @include fontSize(24); 
    //line-height:1.24; 
    color:$slate-grey;
  }

  .smallfont{ font-size:0.875rem;}
  .mar-b-30{ margin-bottom:1.875rem;}
  .mar-t-10{ margin-top:0.625rem;}

  .subcopy{ 
    // position: absolute; 
    // bottom: 0; 
    // margin-bottom: 10px; 
    font-style: italic; 
    color:$slate-grey; 
  }

  .bold{
    font-weight:$bold;
  }
  
  @include breakpoint('mobile') {
    .step { 
      width: 100% !important; 
    }
  }


  .container{ max-width: 1108px;}

 
  .hide-desktop{ display: block;}
  .hide-mobile{ display: none;}
  @include breakpoint('mobile') {
    .hide-mobile{ display: block;}
    .hide-desktop{ display: none;}
    
  }

  @include breakpoint('tablet') {
    .hide-mobile{ display: block;}
    .hide-desktop{ display: none;}
  }

}

// buttons

.buttons{ 
  justify-content: center;
}

.btn {
  background-color: rgba(0,0,0,0);

  &.btn-link { 
    color: $indigoBlue; 
    font-size: remSize(18); 
    text-decoration: underline; 
    font-weight:$bold;
    vertical-align: baseline;
  }
  
  &.btn-link:focus, .focus{ 
    box-shadow: 0 0 0 0.2rem rgba(168, 197, 230, 0.25) !important;}

  @include breakpoint('tablet') {
    &.btn-link:hover{color: $indigoBlue;} 
  }

  @include breakpoint('tablet') {
    &.btn-link:hover{color: $indigoBlue;}   
  }

  &.radio-btn {
    border: 1px solid $indigoBlue;
    background: $grey3;
    border-radius: 33px;
    color:$slate-grey;
    //display: flex;
    //flex-direction: column;
    align-items: center;
    //justify-content: center;
    min-width: 200px;
    font-size:1.12rem;
    font-weight: bold;
    margin: 10px;
    padding-top: remSize(20);
    padding-bottom: remSize(20);
    padding-left: remSize(10);
    padding-right: remSize(10);
    text-align:center;
    
    img {
      //width: 50px;
      //height: 50px;
      // border-radius: 50%;
      margin-bottom:10px;
    }

    .text{ 
      font-size:20px; color:$black; 
    }

    &:hover { 
      border: 5px solid $indigoBlue; 
      padding-top: remSize(15);
      padding-bottom: remSize(15);

      @include breakpoint('tablet') {
        border: 1px solid $indigoBlue; color: $slate-grey;
        padding-top: remSize(20);
        padding-bottom: remSize(20);
      }
    }

    &.active {
      border-color: $macaroni-and-cheese;
      border-width: 5px;
      background: $white;
      padding-top: remSize(16);
      padding-bottom: remSize(16);
    }
  }


// &:focus, .focus{ box-shadow: 0 0 0 0.1rem $macaroni-and-cheese !important;}
}

.help-text-container{
  display: flex; 
  width:70%; 
  margin:0 auto; 
  margin-bottom:10px;

   @include breakpoint('tablet') {
    width:100%;
  }
  
  img{ margin-right:remSize(10);}
}

.help-text {
  color: $indigoBlue; 
  font-weight:bold;
  font-size:1.12rem;
  display:flex;
  text-align: left;
  //margin-bottom:0.8rem;
}

.info-text {
  color: $indigoBlue;
  font-size:1.12rem;
  font-style:italic;
  display:flex;
  text-align: left;
  align-items: center;
}

.instruction-text { 
  color: $indigoBlue; 
  font-style: italic;
  text-align:left; 
}

.icon { 
  width: 3.125rem; 
  height: 3.125rem;

  &.small {
    width: 24px;
    height:24px;
    margin-right: 10px;
  }

  // &.mona {
  //   background: url('/images/Mona-standard.svg');
  //   background-position: center center;
  //   background-color: transparent; 
  //   background-repeat: no-repeat;
  //   background-size: remSize(50) remSize(50);
  //   //margin-right: remSize(16);
  //   width: 4rem;
  // }

  // &.mona-info {
  //   background: url('/images/Mona-info.svg');
  //   background-position: center center;
  //   background-color: transparent; 
  //   background-repeat: no-repeat;
  //   background-size: remSize(50) remSize(50);
  //   margin-right: remSize(16);
  // }

  &.mona-lisa {
    width: 3.125rem; 
    height: 3.125rem;
  }
}



// Modifiers //
.no-margin {
  margin: 0;
}
.block {
  display: block;
}

// IE11 and below
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  h1, h2, h3, h4, h5 { width: 100%; }
  p { width: 100%; } 
  span { width: 100%; } 

  // fix footer position problems
  html, body {
  margin: 0;
  display: flex;
  // flex-grow: 1;
  width: 100%;
  }
  body {
    flex-direction: column;
    min-height: 100vh;
    height: 100vh;
  }
  
  // fix EAR-137 & other IE1 Flex related issues 
  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .page {flex: 1 0 auto;}

  .home .intro {
   display: flex;
    flex-direction: column; 
  }
  .home .intro .top .panel-container .panel {
    // min-height: 650px;
  }
}
