// Breakpoints

$breakpoints: (
  'mobile':  767px,
  'tablet': 1023px,
  'desktop':  1200px
) !default;

// breakpoints mixin
// Usage: @include breakpoint('mobile') {}

@mixin breakpoint($breakpoint) {
	// Check for breakpoint in map
  @if map-has-key($breakpoints, $breakpoint) {
    // Add media query with breakpoint key value
    @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

// Calculate Font Size in Rem
// Zeplin designs have a different font size ratio than represented in CSS
// This function calculates the correct size by multiplying it x 0.75
// It then divides it by 16 (Base font size) to obtain size in Rem

@function calculateRem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  $actualValue: $size * 0.75;
  font-size: $actualValue + px; //Fallback in px for IE9/10
  font-size: calculateRem($actualValue);
}

// Remsize function, renders the size in rems instead of pixels, no calculation done
@function remSize($pxsize) {
  @return ($pxsize/16)+rem;
}
