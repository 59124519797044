@import '../../../css/variables';
@import '../../../css/functions';

.setup {
  .how-often {
    .content {
      @include breakpoint('mobile') {
      width: 100%;
      }    
  section {
    .buttons {
    .radio-btn {
    height: 85px;
    }
    }
  &.bottom {
    min-height: 180px;
    }
    }
}
    }
}