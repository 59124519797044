@import '../../../../css/variables';
@import '../../../../css/functions';

.setup { 

  .volume-control {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    // cant change volume on ios devices

    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */ 
      .percent { display: none; }
    }

    .percent { margin-top: 10px; }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      @include breakpoint('mobile') {
        flex-direction:row;
      }

      .btn { 
        margin: 5px; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .btn-play {
        padding: 0px;
        min-width: 120px;
        min-height: 120px;
        width: 120px;
        height: 120px;
        background: $indigoBlue;
        justify-content: center;
        align-items: stretch;

        .play-image {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            border: none;
            width: 40px;
          }

          span { width: 70%; }
        }

        border: none;
        border-radius: 50%;
        background: $indigoBlue;
        font-size: remSize(16);

        color: $white; 
      }


      .btn-volume {

        min-width: 86px;
        min-height: 86px;
        width: 86px;
        height: 86px;

        @include breakpoint('mobile') {
          min-width: 70px;
          min-height: 70px;
          width: 70px;
          height: 70px;
        }

        // cant change volume on ios devices

        @supports (-webkit-overflow-scrolling: touch) {
          /* CSS specific to iOS devices */ 
          display: none;
        }

        border-radius: 50%;
        background-color: $white;
        border:1px solid $indigoBlue;
        padding: 0px;

        font-size: 3rem; 

        .plus-minus {   position: relative; top: -5px; } 
      }
    }
  }
}