@import '../../css/variables';

.result {
	.top{width: 100%;}
	.step { width: 100%; }
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding:0!important;
	flex-grow: initial !important;
	section { margin-bottom: 10px; }
}