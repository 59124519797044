@import '../../../css/variables';
@import '../../../css/functions';

.test {
  .intro {  
    width:75%;
    text-align:center;
  h2{ color:$indigoBlue;}
    .b {

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        width:100%;
       }
    p:nth-child(1) { color: $indigoBlue; font-weight: bold; }
    p:nth-child(2) { font-style: italic; color: $grey2; }
     }
  .btn-next{ margin-left:0;}
  .alt-next-button.btn-next {  
    background: $indigoBlue;
    color: $white;
    margin-top:20px;
    .arrow { 
    background: url('/images/Icon_White_Chevron.svg'); 
    }
    &:hover{ 
    background: $lightPurple; 
    color:$white;
    }  
      }
  }
}