@import '../../css/variables';
@import '../../css/functions';

.practice {

   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;

   .step { 
    padding-top:2rem;
    h3,h4 {margin-top:20px;}
    }
     	
   section { margin: 12px 0 20px 0; }

   .btn-repeat, .btn-skip { align-self: flex-end; }

   .footnote {
    display: flex;
    flex-flow: column nowrap;
    margin-top:auto;
    text-align:center;
    }

   .bottom {
    display:flex; 
    margin-left:-10px;
    margin-right:-10px;

   @include breakpoint('tablet') {
    & {flex-wrap:wrap;}
}
    }

}