@import '../../../css/variables';
@import '../../../css/functions';

.result {
  .step.intro {
    width: 100%;
    text-align:center;
    h4{ margin-top:30px;}
    .icon{ width:2rem; height:2rem;}
    .t3 { font-style: italic; color: $grey2; font-size:18px; }
    .earimg{ 
      height:100%; 
      width: 390px;
      @include breakpoint('tablet') {
        height:100%;
        width: 100%;
      }    
    }

  section.panel {          
    background: $macaroni-and-cheese;
    margin-bottom: 0;
    padding-right: 40px;
    @include breakpoint('tablet') {
      padding-right: remSize(16); 
    }
    border:none;
      .content {
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            width: 100%;
        }
    .text-box {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .icon { margin-bottom: 10px; }
      .btn-next { background: $white; margin-left:0; }
      h2{ text-align: left;}
      p { text-align: left; }
        @include breakpoint('mobile') {align-items: center;}
        }       
            }
        }
    }

}
