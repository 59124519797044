@import '../../../css/variables';
@import '../../../css/functions';

.setup {
  .step.age-range {
    width: 45%; 
    .form-group { width: 60%; }
    .info-container { display: flex; }
    }
}
