@import '../../../css/variables';
@import '../../../css/functions';

.test {
  .choose-sound {
  .btn-link.disabled {
    color: #6c757d !important;
  }
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;  
  section {
    margin-top: 10px;
    margin-bottom: 10px;
        }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top:0;
    margin-bottom:25px;
    }
  .t2 { color:$slate-grey; font-style:italic;}
  .repeats-container { margin-left:5px;}
    .controls {
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint('mobile') {
        display:block; 
      }
  }
  @include breakpoint('mobile') {
    .buttons {display:block; }
  }
  button img { margin-right: 10px; }
  }
}


