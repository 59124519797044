@import '../../css/variables';

.test {
	display: flex;
	justify-content: center;

	section { margin-bottom: 10px; }
	.btn-pause { 
		img { margin-right: 10px; }
		align-self: flex-end;
	}
	.step{ padding-top:2rem;}
}