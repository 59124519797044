// system wide variables

//Fonts Files Path
$fontsPath : "/fonts";

//$headerHeight: 40px;
$stepBorderWidth: 10px;

// colors

$white: #fff;
$black: #000;

$grey: #e1e0e1; //mid gray
$grey1: #f7f7f7; // light gray
$grey2: #989898; // dark gray
$grey3: #f6f6f6; // lighter gray - radio button
$darkgrey:#d5d5d5;
$borderColor: #cacaca;

$green: rgb(105, 190, 40);
$red: #f00;
$errorRed: #a94442;
$smallfont:18px;
$bold:700;

$indigoBlue: #3f1482;
$macaroni-and-cheese: #fdc82f;
$darkOrange:  #ffb000;
$slate-grey: #56565a;
$purple: #672AC5; 	// Button - Hover
$lightPurple: #4e03c0;

$padding1: 10px;


$borderRadius: 15px;