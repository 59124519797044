@import '../../css/variables';
@import '../../css/functions';

.app-footer {
  $footerBackgroundColor: #626166;
  $footerTextColor: white;
  $footerCopyrightBgColor: black;
  background: #1e1e1e;

  .cochlear-footer {
    background: #1e1e1e;
    color: $footerTextColor;
    .content {
      padding: 15px 10px;
      //height: 88px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      max-width: 1108px;
      margin: 0 auto;

     .footer-logo { width:20%; text-align:left;
      @include breakpoint('tablet') {
       width:100%;
       text-align: left;
      }
    }
     .footer-links { width:50%; text-align:right;
       a{ padding:0 20px;
        @include breakpoint('tablet') {
          display:block; 
          padding: 0;
         }
      }
       @include breakpoint('tablet') {
        width:100%;
        text-align: left;
       }
    }

      @include breakpoint('tablet') {
      flex-direction: column;
      padding: 15px 0;
    }


    }

    img.logo { width: 50px; }
    a { font-size: 18px; 
      span { white-space: nowrap; }
    }

    .copyright { font-size: 12px; text-align:right; width:30%;
      text-align:right;
      @include breakpoint('tablet') {
        text-align: left;
         width:100%;
      }
    }
  }
  a { 
    color: $footerTextColor; 
    //text-decoration: none;    
  }
  a:hover { 
    color: $footerTextColor  
  }
}